import { lazy, useEffect } from "react";

const Dashboard = lazy(() => import("../views/dashboard/"));

// -------------------- Location ----------------------- //
const StatePage = lazy(() => import("../views/location/StatePage"));
const CityPage = lazy(() => import("../views/location/CityPage"));
const AreaPage = lazy(() => import("../views/location/AreaPage"));
// -------------------- Location ----------------------- //

// -------------------- ListingPages ----------------------- //
const SuperCategoryPage = lazy(() => import("../views/listing/SuperCategory"));
const ListingCategoryPage = lazy(() => import("../views/listing/ListingCategory"));
const ListingSubCategoryPage = lazy(() => import("../views/listing/ListingSubCategory"));
const ListingFeaturesPage = lazy(() => import("../views/listing/Features"));
const ApprovalListingPage = lazy(() => import("../views/listing/")); // Approval Page
// -------------------- ListingPages ----------------------- //

// -------------------- EventPages ----------------------- //
const EditEventPage = lazy(() => import("../views/event/edit"));
const CategoryPage = lazy(() => import("../views/event/CategoryPage"));
const SubCategoryPage = lazy(() => import("../views/event/SubCategoryPage"));
const ApprovalEventPage = lazy(() => import("../views/event/")); // Approval Page
// -------------------- EventPages ----------------------- //

// -------------------- User & Review ----------------------- //
const UsersPage = lazy(() => import("../views/Users/"));
const ReviewPage = lazy(() => import("../views/Users/reviews"));
// -------------------- User ----------------------- //

// -------------------- Videos ----------------------- //
const VideosCategory = lazy(() => import("../views/Videos/VideosCategory"));
const Videos = lazy(() => import("../views/Videos/Videos"));
// -------------------- Videos ----------------------- //

// -------------------- SMS ----------------------- //
const smsCategoryPage = lazy(() => import("../views/sms/CategoryPage"));
const MessagesPage = lazy(() => import("../views/sms/MessagesPage"));
const RequestPage = lazy(() => import("../views/sms/RequestPage"));
// -------------------- SMS ----------------------- //

// -------------------- Names ----------------------- //
const BabyCategoryPage = lazy(() => import("../views/names/NamesCategoryPage"));
const PopularCategoryPage = lazy(() => import("../views/names/PopularCategoryPage"));
const NamesPage = lazy(() => import("../views/names/NamesPage"));
// -------------------- Names ----------------------- //

// -------------------- Mobiles ----------------------- //
const BrandPage = lazy(() => import("../views/mobiles/BrandPage"));
const GroupPage = lazy(() => import("../views/mobiles/GroupPage"));
const FeaturesPage = lazy(() => import("../views/mobiles/FeaturesPage"));
const ListingPage = lazy(() => import("../views/mobiles/ListingPage"));
const AddPage = lazy(() => import("../views/mobiles/AddPage"));
const EditPage = lazy(() => import("../views/mobiles/EditPage"));
// -------------------- Mobiles ----------------------- //

// -------------------- Prices ----------------------- //
const Prices = lazy(() => import("../views/prices/"));
const PriceCategory = lazy(() => import("../views/prices/category"));
const GoldPage = lazy(() => import("../views/prices/gold"));
const PetrolPage = lazy(() => import("../views/prices/petrol"));
// -------------------- Prices ----------------------- //

// -------------------- Ringtones ----------------------- //
const RingtonesCategoryPage = lazy(() => import("../views/rigntones/RingtonesCategoryPage"));
const RingtonesPage = lazy(() => import("../views/rigntones/RingtonesPage"));
// -------------------- Ringtones ----------------------- //

// -------------------- Help ----------------------- //
const HelpCategoryPage = lazy(() => import("../views/help/HelpCategoryPage"));
const HelpPage = lazy(() => import("../views/help/HelpPage"));
// -------------------- Help ----------------------- //

// -------------------- Website ----------------------- //
const GuestPostPage = lazy(() => import("../views/guestpost/"));
const CareerPage = lazy(() => import("../views/career/"));
const PackagesPage = lazy(() => import("../views/packages/"));
const PaymentPage = lazy(() => import("../views/payments/"));
const AuctionSheetPage = lazy(() => import("../views/auctionsheet/"));
const SeoPage = lazy(() => import("../views/seo/"));
const Error = lazy(() => import("../views/error"));
const Actions = lazy(() => import("../views/actions"));
const Claim = lazy(() => import("../views/claim"));
const Contact = lazy(() => import("../views/contact"));
// -------------------- Website ----------------------- //

// -------------------- Stock ----------------------- //
const StockCategoryPage = lazy(() => import("../views/stock/StockCategory"));
const StockPage = lazy(() => import("../views/stock/Stock"));
// -------------------- Stock ----------------------- //

const Redirect = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/dashboard";
    }, 1000);
  }, []);
  return <>Redirecting to Dashboard....</>;
};

const PrivateRoutes = [
  { exact: true, path: "/dashboard", component: Dashboard },
  { exact: true, path: "/state", component: StatePage },
  { exact: true, path: "/city", component: CityPage },
  { exact: true, path: "/area", component: AreaPage },
  { exact: true, path: "/listing", component: ApprovalListingPage },
  { exact: true, path: "/listing/supercategory", component: SuperCategoryPage },
  { exact: true, path: "/listing/category", component: ListingCategoryPage },
  { exact: true, path: "/listing/subcategory", component: ListingSubCategoryPage },
  { exact: true, path: "/listing/features", component: ListingFeaturesPage },
  { exact: true, path: "/event/:id", component: EditEventPage },
  { exact: true, path: "/events", component: ApprovalEventPage },
  { exact: true, path: "/events/category", component: CategoryPage },
  { exact: true, path: "/events/subcategory", component: SubCategoryPage },
  { exact: true, path: "/users", component: UsersPage },
  { exact: true, path: "/reviews", component: ReviewPage },
  { exact: true, path: "/sms/category", component: smsCategoryPage },
  { exact: true, path: "/sms/messages", component: MessagesPage },
  { exact: true, path: "/sms/messages/requests", component: RequestPage },
  { exact: true, path: "/names/category", component: BabyCategoryPage },
  { exact: true, path: "/names/popular-category", component: PopularCategoryPage },
  { exact: true, path: "/names", component: NamesPage },
  { exact: true, path: "/mobile/brand", component: BrandPage },
  { exact: true, path: "/mobile/group", component: GroupPage },
  { exact: true, path: "/mobile/features", component: FeaturesPage },
  { exact: true, path: "/mobile", component: ListingPage },
  { exact: true, path: "/mobile/add", component: AddPage },
  { exact: true, path: "/mobile/edit/:id", component: EditPage },
  { exact: true, path: "/prices/", component: Prices },
  { exact: true, path: "/prices/category", component: PriceCategory },
  { exact: true, path: "/prices/gold", component: GoldPage },
  { exact: true, path: "/prices/petrol", component: PetrolPage },
  { exact: true, path: "/rigntones/category", component: RingtonesCategoryPage },
  { exact: true, path: "/ringtones", component: RingtonesPage },
  { exact: true, path: "/help", component: HelpPage },
  { exact: true, path: "/help/category", component: HelpCategoryPage },
  { exact: true, path: "/guest-post", component: GuestPostPage },
  { exact: true, path: "/career", component: CareerPage },
  { exact: true, path: "/stock/category", component: StockCategoryPage },
  { exact: true, path: "/stock", component: StockPage },
  { exact: true, path: "/packages", component: PackagesPage },
  { exact: true, path: "/payments", component: PaymentPage },
  { exact: true, path: "/auction-sheet", component: AuctionSheetPage },
  { exact: true, path: "/seo", component: SeoPage },
  { exact: true, path: "/videos/category", component: VideosCategory },
  { exact: true, path: "/videos", component: Videos },
  { exact: true, path: "/error-log", component: Error },
  { exact: true, path: "/actions-log", component: Actions },
  { exact: true, path: "/claim-log", component: Claim },
  { exact: true, path: "/contact", component: Contact },
  { exact: true, path: "/auth", component: Redirect },
];

export default PrivateRoutes;
