import { Redirect, Switch, Route } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Auth } from "../../context/auth";
import { Suspense, useEffect } from "react";
import Loader from "../loader";

const Routes = () => {
  const { isAuthenticated } = Auth();

  return (
    <Switch>
      {isAuthenticated ? (
        <MasterLayout>
          <Suspense fallback={<Loader />}>{PrivateRoutes && PrivateRoutes.map((route, i) => <Route key={i} exact={route.exact} path={route.path} component={route.component} />)}</Suspense>
        </MasterLayout>
      ) : (
        PublicRoutes && PublicRoutes.map((route, i) => <Route key={i} exact={route.exact} path={route.path} component={route.component} />)
      )}

      <Route path="/error" component={ErrorsPage} />
      <Redirect to="/auth" />
    </Switch>
  );
};

export { Routes };
