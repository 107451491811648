import Auth from "../views/auth";

const PublicRoutes = [
  {
    id: "auth",
    path: "/auth",
    exact: false,
    component: Auth,
  },
];

export default PublicRoutes;
