/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem to="/dashboard" icon="/media/icons/duotune/art/art002.svg" title={intl.formatMessage({ id: "MENU.DASHBOARD" })} />

      {/* ----------- Location --------- */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Location</span>
        </div>
      </div>
      <AsideMenuItem to="/state" icon="/media/icons/duotune/maps/map004.svg" title="State" />
      <AsideMenuItem to="/city" icon="/media/icons/duotune/maps/map005.svg" title="City" />
      <AsideMenuItem to="/area" icon="/media/icons/duotune/maps/map006.svg" title="Area" />
      {/* ----------- Location --------- */}

      {/* ----------- Listing --------- */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Listing</span>
        </div>
      </div>
      <AsideMenuItem to="/listing/supercategory" icon="/media/icons/duotune/general/gen008.svg" title="Super Category" />
      <AsideMenuItem to="/listing/category" icon="/media/icons/duotune/general/gen009.svg" title="Category" />
      <AsideMenuItem to="/listing/subcategory" icon="/media/icons/duotune/general/gen010.svg" title="Sub Category" />
      <AsideMenuItem to="/listing/features" icon="/media/icons/duotune/general/gen003.svg" title="Features" />
      <AsideMenuItem to="/listing" icon="/media/icons/duotune/general/gen004.svg" title="Listing" />
      {/* ----------- Listing --------- */}

      {/* ----------- Events --------- */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Events</span>
        </div>
      </div>
      <AsideMenuItem to="/events/category" icon="/media/icons/duotune/general/gen009.svg" title="Category" />
      <AsideMenuItem to="/events/subcategory" icon="/media/icons/duotune/general/gen010.svg" title="Sub Category" />
      <AsideMenuItem to="/events" icon="/media/icons/duotune/general/gen004.svg" title="Events" />
      {/* ----------- Events --------- */}

      {/* ----------- Users ---------- */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Users </span>
        </div>
      </div>
      <AsideMenuItem to="/users" icon="/media/icons/duotune/communication/com006.svg" title="Users" />
      <AsideMenuItem to="/reviews" icon="/media/icons/duotune/communication/com003.svg" title="Reviews" />
      {/* ----------- Users ----------- */}

      {/* -------------- SMS ----------------- */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">SMS</span>
        </div>
      </div>
      <AsideMenuItem icon="/media/icons/duotune/communication/com011.svg" to="/sms/category" title="Category" />
      <AsideMenuItem icon="/media/icons/duotune/communication/com011.svg" to="/sms/messages" title="Messages" />
      <AsideMenuItem icon="/media/icons/duotune/communication/com011.svg" to="/sms/messages/requests" title="Requests" />
      {/* ----------- SMS ---------------- */}

      {/* ----------- Baby Names -------------- */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Names </span>
        </div>
      </div>
      <AsideMenuItem icon="/media/icons/duotune/text/txt005.svg" to="/names/category" title="Category" />
      <AsideMenuItem icon="/media/icons/duotune/text/txt005.svg" to="/names/popular-category" title="Popular Category" />
      <AsideMenuItem icon="/media/icons/duotune/text/txt005.svg" to="/names" title="Names" />
      {/* ----------- Baby Names -------------- */}

      {/* ----------- Mobiles ------------- */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Mobiles</span>
        </div>
      </div>
      <AsideMenuItem icon="/media/icons/duotune/technology/teh002.svg" to="/mobile/brand" title="Brand" />
      <AsideMenuItem icon="/media/icons/duotune/technology/teh002.svg" to="/mobile/group" title="Groups" />
      <AsideMenuItem icon="/media/icons/duotune/technology/teh002.svg" to="/mobile/features" title="Features" />
      <AsideMenuItem icon="/media/icons/duotune/technology/teh002.svg" to="/mobile" title="Mobiles" />
      {/* ----------- Mobiles -------------- */}

      {/* ----------- Prices ------------- */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Prices</span>
        </div>
      </div>
      <AsideMenuItem icon="/media/icons/duotune/finance/fin001.svg" to="/prices/category" title="Category" />
      <AsideMenuItem icon="/media/icons/duotune/finance/fin003.svg" to="/prices" title="Prices" />
      <AsideMenuItem icon="/media/icons/duotune/finance/fin005.svg" to="/prices/gold" title="Gold" />
      <AsideMenuItem icon="/media/icons/duotune/finance/fin010.svg" to="/prices/petrol" title="Petrol" />
      {/* ----------- Prices -------------- */}

      {/* ----------- Rigntones -----------------*/}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Rigntones</span>
        </div>
      </div>
      <AsideMenuItem icon="/media/icons/duotune/social/soc008.svg" to="/rigntones/category" title="Category" />
      <AsideMenuItem icon="/media/icons/duotune/social/soc008.svg" to="/ringtones" title="Ringtones" />
      {/* ----------- Rigntones ---------------*/}

      {/* ----------- Videos -----------------*/}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Videos</span>
        </div>
      </div>
      <AsideMenuItem icon="/media/icons/duotune/social/soc007.svg" to="/videos/category" title="Videos Category" />
      <AsideMenuItem icon="/media/icons/duotune/social/soc007.svg" to="/videos" title="Videos" />
      {/* ----------- Videos ---------------*/}

      {/* ----------- Stock -----------------*/}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Image & Video Stock</span>
        </div>
      </div>
      <AsideMenuItem icon="/media/icons/duotune/art/art010.svg" to="/stock/category" title="Category" />
      <AsideMenuItem icon="/media/icons/duotune/art/art010.svg" to="/stock" title="Stock" />
      {/* ----------- Stock ---------------*/}

      {/* ----------- Help -----------------*/}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Help</span>
        </div>
      </div>
      <AsideMenuItem icon="/media/icons/duotune/maps/map010.svg" to="/help/category" title="Category" />
      <AsideMenuItem icon="/media/icons/duotune/maps/map010.svg" to="/help" title="Help" />
      {/* ----------- Help ---------------*/}

      {/* ----------- Website ------------*/}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Website</span>
        </div>
      </div>
      <AsideMenuItem to="/payments" icon="/media/icons/duotune/abstract/abs025.svg" title="Payments" />
      <AsideMenuItem to="/guest-post" icon="/media/icons/duotune/abstract/abs001.svg" title="Guest Posts" />
      <AsideMenuItem to="/packages" icon="/media/icons/duotune/abstract/abs045.svg" title="Packages" />
      <AsideMenuItem to="/career" icon="/media/icons/duotune/abstract/abs034.svg" title="Career" />
      <AsideMenuItem to="/contact" icon="/media/icons/duotune/abstract/abs012.svg" title="Contact" />
      <AsideMenuItem to="/auction-sheet" icon="/media/icons/duotune/abstract/abs032.svg" title="Auction Sheet" />
      <AsideMenuItem to="/seo" icon="/media/icons/duotune/abstract/abs010.svg" title="SEO" />
      <AsideMenuItem to="/error-log" icon="/media/icons/duotune/abstract/abs007.svg" title="Error Log" />
      <AsideMenuItem to="/actions-log" icon="/media/icons/duotune/abstract/abs003.svg" title="Actions Log" />
      <AsideMenuItem to="/claim-log" icon="/media/icons/duotune/abstract/abs021.svg" title="Claim Log" />
      {/* ----------- Website ----------------*/}
    </>
  );
}
