import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loader = () => {
  return (
    <div className="row">
      <div className="col-sm-4">
        <Skeleton baseColor="#fff" highlightColor="#eee" height="300px" width="100%" />
      </div>
      <div className="col-sm-8">
        <Skeleton baseColor="#fff" highlightColor="#eee" height="300px" width="100%" />
      </div>
    </div>
  );
};

export default Loader;
