/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import React, { useEffect , useState} from "react";
import { useLocation } from "react-router-dom";
import { MenuComponent } from "../../../assets/ts/components";
import { useLayout } from "../../core";
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

export function HeaderWrapper() {
  const { pathname } = useLocation();
  const { config, classes, attributes } = useLayout();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, [pathname]);

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshCycle");

    window.location.reload();
  };

  return (
    <div
      id="kt_header"
      className={clsx(
        "header",
        classes.header.join(" "),
        "align-items-stretch"
      )}
      {...attributes.headerMenu}
    >
      <div
      className={`ms-4 mt-3 btn d-sm-block d-none`} 
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            id="kt_app_sidebar_mobile_toggle"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
												<rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
												<rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
												<rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
											</svg>
          </div>
          <button className="ms-5 d-block d-sm-none btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
												<rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
												<rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
												<rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
											</svg></button>
      <div
        className={clsx(
          classes.headerContainer.join(" "),
          "d-flex align-items-stretch justify-content-end"
        )}
      >
        
        <div onClick={logout} className="d-flex align-items-center ms-n3 me-1">
          <div className="btn btn-icon btn-primary w-100px h-30px">
            <FontAwesomeIcon icon={faSignOutAlt} /> &nbsp; Logout
          </div>
        </div>
        {/* end::Aside mobile toggle */}
      </div>
    </div>
  );
}
