/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const Login = lazy(() => import("./login"));

const AuthPage = () => {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl("/media/illustrations/sketchy-1/14.png")})`,
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="#" className="mb-12">
          <img alt="Logo" src={"/favicon.png"} className="h-45px" />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Switch>
            <Route component={Login} />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-bold fs-6">
          <a href="https://wellcreator.com" target="_blank" className="text-muted text-hover-primary px-2">
            Developed By Wellcreator
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  );
};

export default AuthPage;
