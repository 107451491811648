import React from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "./context/auth";
import { App } from "./app/App";
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";

const { PUBLIC_URL } = process.env;

ReactDOM.render(
  <AuthProvider>
    <MetronicI18nProvider>
      <App basename={PUBLIC_URL} />
    </MetronicI18nProvider>
  </AuthProvider>,
  document.getElementById("root")
);
