import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const [routes, setRoutes] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const paths = location.pathname.split("/");
    paths.shift();
    setRoutes(paths);
  }, [location.pathname]);

  return (
    <div className="container mb-5 mt-n3">
      <span className="text-uppercase">Home</span>
      <span className="mx-2">{">"}</span>

      {routes &&
        routes.length > 0 &&
        routes.map((route, i) => (
          <span key={i}>
            <span className="text-uppercase">{route.replace(/-/g, " ")}</span>
            {routes.length !== i + 1 && <span className="mx-2">{">"}</span>}
          </span>
        ))}
    </div>
  );
};

export default Breadcrumbs;
